@import "../styles.lib.scss";
@import "suggestions.mixin.scss";

.scope-ssw {
    .searchbox,
    .searchbox-grid {
        .new-suggestions {
            display: none;

            .suggestions-container.multiple-columns {
                display: flex;
                flex-direction: row;

                &:has(> .feature-promotion):has(> .article-column-container) {
                    flex-direction: column;
                    width: 600px;
                }

                ul.suggestions-list-container {
                    margin: 10px 0;
                    padding: 0;
                    width: 300px;
                    padding-right: 10px;
                    border-right: solid 1px $lu21-color-e-s-mid-grey;

                    &:only-child {
                        padding-right: 0;
                        border-right: none;
                    }

                    li {
                        @include list-item-base-layout;

                        &.suggestions-text,
                        &.top-search-query-text {
                            padding-left: 10px;
                        }

                        a {
                            padding-left: 25px;
                        }
                    }
                }

                .article-column-container {
                    @include article-column-base-layout;
                    margin-top: 10px;

                    &.padded {
                        padding-left: 10px;
                    }

                    span.suggestions-text {
                        width: 290px;
                        padding: 8px 10px 0 0;
                    }

                    .article-container {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        max-width: 600px;

                        div.suggestion-item {
                            &:nth-child(even) {
                                margin-left: 20px;
                            }

                            a {
                                width: 290px;
                            }
                        }

                        .result-button-container {
                            width: 290px;
                        }
                    }
                }
            }

            .suggestions-container.multiple-columns.hidden,
            .suggestions-container.merged-column.hidden {
                display: none;
            }

            .hidden {
                display: none;
            }
        }

        &.focus {
            .ssw-backdrop {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $search-backdrop-grey;
                opacity: 0.25;
                z-index: -1;
            }

            .new-suggestions {
                display: block;
                width: fit-content;
                max-height: 850px;
                background-color: $color-e-s-white;
                color: $lu21-color-e-s-all-black;
                z-index: -1;
                padding: 6px 16px;

                &.empty,
                &.hidden {
                    display: none;
                }
            }
        }

        &.esApp {
            .new-suggestions {
                max-height: initial;
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox.legacy,
    .searchbox-grid.legacy {

        .suggestions {
            display: none;
            
            ul {
                margin: 0;
                padding: 0;

                &.left {
                    width: 50%;
                    float: left;
                    padding-right: 8px;
                }

                &.right {
                    width: 48%;
                    float: right;
                }

                li {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    cursor: pointer;

                    &.suggestions-text,
                    &.top-search-query-text {
                        color: $lu21-color-e-s-dark-grey;
                        cursor: default;
                        text-transform: uppercase;
                        font-weight: bold;
                    }

                    &.suggestions-text,
                    &.top-search-query-text,
                    a {
                        padding-right: 10px;
                    }

                    &.suggestions-text,
                    &.top-search-query-text {
                        padding-left: 10px;
                    }

                    a {
                        padding-left: 25px;
                    }

                    a {
                        color: inherit;
                        text-decoration: inherit;
                        text-overflow: ellipsis;
                        display: inherit;
                        overflow: hidden;
                    }

                    strong {
                        font-weight: bold;
                    }

                    &.is-touched:not(.suggestions-text, .top-search-query-text),
                    &.is-selected:not(.suggestions-text, .top-search-query-text) {
                        background-color: $lu21-color-e-s-mid-grey;
                    }
                }
            }
        }

        &.focus {
            .ssw-backdrop {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: $search-backdrop-grey;
                opacity: 0.25;
                z-index: -1;
            }

            .suggestions {
                display: block;
                width: 342px;
                background-color: $color-e-s-white;
                color: $lu21-color-e-s-all-black;
                z-index: -1;
                padding: 6px 16px;

                &.empty,
                &.hidden {
                    display: none;
                }

                &.multiple-lists {
                    width: 660px;
                }
            }
        }
    }
}

@import "suggestions.wide.scss";
@import "suggestions.flyout.scss";
@import "suggestions.grid.scss";
