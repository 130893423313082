@import "../styles.lib.scss";
@import "searchbox.mixin.scss";

.scope-ssw {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    line-height: 15px;
    font-size: 13px;
    letter-spacing: 0.3px;

    &,
    & *,
    & *::before,
    & *::after {
        box-sizing: border-box;
    }

    svg {
        overflow: visible;
    }

    .searchbox {
        .search {
            form {
                height: 50px;

                .ssw-button,
                .ssw-input {
                    position: absolute;
                    right: 0;
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    outline: none;
                }

                .ssw-button,
                .ssw-input,
                .ssw-input::placeholder {
                    font-family: Roboto, Arial, Helvetica, sans-serif;
                    font-size: 14px;
                    color: $lu21-color-e-s-all-black;
                }

                .ssw-button {
                    width: 40px;
                    height: 40px;
                    z-index: 1;

                    svg {
                        display: block;
                        position: absolute;
                        top: 8px;
                        left: 10px;
                        width: 24px;
                        height: 24px;
                    }
                }

                .ssw-input {
                    width: 300px;
                    padding-right: 34px;
                    border-bottom: 1px solid $lu21-color-e-s-all-black;
                }

                .ssw-input:focus {
                    @include searchbox-focus-input;
                }
            }
        }

        &.focus .search form .ssw-input {
            @include searchbox-focus-input;
        }
    }
}

@import "searchbox.wide.scss";
@import "searchbox.flyout.scss";
