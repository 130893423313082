@import "../styles.lib.scss";

.scope-ssw {
    .searchbox.flyout {
        width: 100vw;
        height: 50px;
        opacity: 0;

        &.focus {
            opacity: 1;
        }

        .ssw-backdrop {
            display: none;
        }

        .search {
            width: 632px;
            margin-left: calc(100vw - 632px);

            &:only-child {
                padding-bottom: 14px;
            }
        }

        @include searchbox-flyout-layout;
    }

    @media only screen and (max-width: 767px) {
        .searchbox.flyout {
            .search {
                margin-left: 0;
                width: 100vw;

                &:only-child {
                    padding-bottom: 0;
                }
            }

            .search form {
                .ssw-input {
                    border-bottom-color: $color-e-s-grey2;
                    margin: 0;
                    width: 100%;
                    padding-left: 74px;
                    padding-right: 10px;
                }

                .ssw-button {
                    right: auto;
                    left: 34px;
                }
            }
        }
    }
}

// include previous styles (without prices and images)
html.desktop {
    .scope-ssw {
        .searchbox.flyout.legacy {
            .search {
                margin-left: 50vw;
                width: 50vw;
                padding-bottom: 14px;

                &.large {
                    margin-left: 20vw;
                    width: 80vw;
                }
            }
        }

        @media only screen and (max-width: 767px) {
            .searchbox.flyout.legacy {
                .search {
                    padding-bottom: 0;
                    margin-left: 0;
                    width: 100vw;

                    &.large {
                        margin-left: inherit;
                        width: inherit;
                    }
                }
            }
        }
    }
}
