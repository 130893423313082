﻿@import "../styles.lib.scss";

.scope-ssw {
    .searchbox.flyout .new-suggestions {
        @include suggestion-wide-layout;
        width: 632px;
        margin-left: calc(100vw - 632px);

        ul {
            padding-top: 0;
        }

        .suggestions-container.multiple-columns {
            .suggestions-list-container:only-child {
                width: 100%;
            }

            .article-column-container .article-container div.suggestion-item.articles {
                border-bottom: 1px solid $lu21-color-e-s-mid-grey;

                &:only-child {
                    border-bottom: 0;
                }
            }

            // only for suggestions without TSQs and content links
            &:has(> .article-column-container:only-child),
            &:has(> .feature-promotion):has(> .article-column-container) {
                div.suggestion-item.articles {
                    // article left of the button
                    &:nth-child(odd):nth-last-child(2) {
                        border-bottom: 0;
                    }
                }
            }

            &:has(> .suggestions-list-container):has(> .article-column-container) {
                .article-container {
                    width: 290px;

                    &.hidden {
                        display: none;
                    }

                    > div {
                        padding-left: 0;
                        margin-left: 0;
                    }
                }
            }
        }

        .suggestions-container.merged-column {
            ul.suggestions-list-container {
                margin: 0;
                padding: 0;
                padding-bottom: 162px;

                li {
                    @include list-item-base-layout;
                    padding-left: 40px;
                    line-height: 15px;
                    border-top: none;
                    border-bottom: 1px solid $lu21-color-e-s-mid-grey;

                    &:last-child {
                        border-bottom: none;
                    }

                    &.suggestions-text.content {
                        border-top: solid 1px $lu21-color-e-s-mid-grey;

                        &:first-child {
                            border-top: none;
                        }
                    }

                    &.suggestions-text,
                    &.top-search-query-text,
                    a {
                        padding-right: 10px;
                    }

                    a {
                        padding-left: 25px;
                    }
                }

                .article-column-container {
                    @include article-column-base-layout;

                    span.suggestions-text {
                        margin-top: 0;
                        padding: 12px 10px 12px 40px;
                        border-bottom: 1px solid $lu21-color-e-s-mid-grey;
                    }

                    .article-container {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: wrap;

                        div.suggestion-item.articles {
                            border-bottom: 1px solid $lu21-color-e-s-mid-grey;

                            a {
                                padding-left: 20px;
                                padding-right: 20px;

                                .article-info {
                                    .article-name {
                                        margin-top: 5px;
                                    }

                                    .article-variants {
                                        margin-bottom: 5px;
                                    }
                                }
                            }
                        }

                        .result-button-container {
                            height: 90px;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 767px) {
            height: calc(100vh - 100px);
            max-height: unset;
            overflow-y: scroll;
            padding: 0;
            margin-left: 0;
            width: 100vw;

            @include suggestion-flyout-layout;

            .suggestions-container.merged-column
                ul.suggestions-list-container
                .article-column-container
                .article-container
                div.suggestion-item
                a
                .article-info
                .article-name {
                width: calc(100vw - 180px);
            }

            ul {
                padding-bottom: 162px;

                li {
                    padding-left: 46px;

                    &.suggestions-text,
                    &.suggestions-text:first-child,
                    &.top-search-query-text,
                    &.top-search-query-text:first-child,
                    a {
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                }
            }
        }
    }
}

// device specific override for mobile
html.mobileshop {
    .scope-ssw .searchbox.flyout {
        .new-suggestions {
            padding: 0;

            .suggestions-container.merged-column {
                ul {
                    padding-bottom: 162px;

                    li {
                        padding-left: 40px;

                        &.suggestions-text,
                        &.top-search-query-text,
                        a {
                            padding-top: 12px;
                            padding-bottom: 12px;
                        }

                        &.suggestions-text.content {
                            border-top: solid 1px $lu21-color-e-s-mid-grey;

                            &:first-child {
                                border-top: none;
                            }
                        }
                    }
                }

                .article-column-container .article-container.compact {
                    @include compact-article-layout(40px, 25px, 10px, 39px, 0px);
                }

                ul.suggestions-list-container
                    .article-column-container
                    .article-container.expanded
                    div.suggestion-item
                    a {
                    padding-left: 40px;
                }
            }
        }
    }
}

// device specific override for mobile in landscape mode
html.mobileshop {
    @media only screen and (min-width: 768px) {
        .scope-ssw .searchbox.flyout {
            .search {
                width: 100vw;
                margin-left: 0;
                margin-top: 0;
            }

            .new-suggestions {
                width: 100vw;
                margin-left: 0;
                max-height: calc(100vh - 100px);
                overflow-y: scroll;

                .suggestions-container.multiple-columns {
                    .suggestions-list-container,
                    .article-column-container {
                        margin-top: 0;
                    }

                    .suggestions-text.articles {
                        padding-top: 0;
                    }

                    &:has(> .suggestions-list-container):has(> .article-column-container) {
                        .article-container {
                            margin-bottom: 10px;

                            > div {
                                padding-left: 0;
                            }

                            .result-button-container {
                                width: 290px;
                            }
                        }
                    }
                }
            }
        }
    }
}

// device specific override for tablet in portrait mode
html.tablet {
    @media only screen and (max-width: 991px) {
        .scope-ssw .searchbox.flyout {
            .search {
                width: 83vw;
                margin-left: 17vw;
            }

            .new-suggestions {
                width: 83vw;
                margin-left: 17vw;
                max-height: calc(100vh - 100px);
                overflow-y: scroll;

                .suggestions-container.multiple-columns {
                    &:has(> .suggestions-list-container):has(> .article-column-container) {
                        .article-container {
                            max-width: 290px;
                            margin-bottom: 10px;

                            > div {
                                padding-left: 0;
                            }
                        }
                    }

                    .article-column-container .article-container .result-button-container {
                        width: 290px;
                        padding-left: 0;

                        &:nth-child(even) {
                            margin-left: 20px;
                        }
                    }

                    &:has(> .feature-promotion):has(> .article-column-container) {
                        width: auto;
                        align-items: center;

                        .article-column-container {
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 720px) {
        .scope-ssw .searchbox.flyout {
            .search {
                width: 100vw;
                margin-left: 0;
            }

            .new-suggestions {
                width: 100vw;
                margin-left: 0;
                height: calc(100vh - 95px);
                max-height: unset;
            }
        }
    }
}

// include previous styles (without prices and images)
.scope-ssw {
    .searchbox.flyout.legacy .suggestions {
        @include suggestion-wide-medium-small-layout(0);
        margin-left: 50vw;
        width: 50vw;

        ul {
            padding-top: 0;

            li.suggestions-text:first-child,
            li.top-search-query-text:first-child {
                padding-top: 0;
            }
        }

        &.multiple-lists {
            margin-left: 20vw;
            width: 80vw;
        }
    }

    @media only screen and (max-width: 767px) {
        .searchbox.flyout.legacy .suggestions,
        .searchbox.flyout.legacy .suggestions.multiple-lists {
            height: calc(100vh - 100px);
            overflow-y: scroll;
            padding: 0px 0 6px;
            margin-left: 0;
            width: 100vw;

            @include suggestion-flyout-layout-legacy;

            ul {
                padding-bottom: 162px;

                &.left,
                &.right {
                    float: none;
                    width: 100vw;
                }

                &.left {
                    padding-right: 0;
                    padding-bottom: 0;
                }

                li {
                    padding-left: 46px;

                    &.suggestions-text,
                    &.suggestions-text:first-child,
                    &.top-search-query-text,
                    &.top-search-query-text:first-child,
                    a {
                        padding-top: 12px;
                        padding-bottom: 12px;
                    }
                }
            }
        }
    }
}
